<template>
  <div class="home_warp">
    <!-- 顶部导航 -->
    <titleNav :title="title" :left-arrow="true" left-text="" />
    <!-- 内容 -->
    <div class="com" v-if="id==1">
        <img src="@/assets/images/shebaochaxun.png" alt="">
        <!-- <div class="tit">
            微信扫码或长按识别“河北人社一体化公共服务平台”小程序，手机办理河北省社保查询、养老保险查询，失业保险查询，失业保险金申领，社保关系转移，参保缴费凭证打印，灵活就业社保补贴申领、就业创业补贴申请、职称查询等业务。
        </div> -->
    </div>
    <div class="com" v-else>
        <img src="@/assets/images/yibaochaxun.png" alt="">
        <!-- <div class="tit">
            微信扫码或长按识别“河北智慧医保”小程序，手机微信办理河北省医疗保险查询、医保缴费记录查询、医保消费记录查询、医保政策智能咨询、门诊慢性病特殊病申报认定，异地就医备案等业务。
        </div> -->
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, Field, Checkbox, CheckboxGroup } from "vant";
import { banner, price, indexs } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
        title:"",
        id:1
    };
  },
  created() {
      let id=this.$route.query.id
      this.id=id
      if(id==1){
          this.title="社保查询方式"
      }else{
          this.title="医保查询方式"
      }
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.home_warp {
//   height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  position: relative;
  padding: 10px;
  .com{
      img{
          width: 100%;
      }
      .tit{
          text-indent:40px;
          font-size: 20px;
      }
  }
}
</style>
